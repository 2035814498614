import React from 'react';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Typography } from '@mui/material';

import { Scenarii } from './pages/Scenarii';
import monogram from './assets/img/monogramBlack.png';

const NotMobile = () => (
  <Box
    sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Typography fontFamily="arial" fontWeight="bold" fontSize="20px">
      Please use your smartphone
    </Typography>
    <Typography fontFamily="arial" fontWeight="bold" fontSize="20px">
      to start the process
    </Typography>
    <img
      src={monogram}
      style={{
        width: '55px',
        margin: '20px'
      }}
    />
  </Box>
);

const App = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [userInfoSaved, setUserInfoSaved] = useState(false);

  useEffect(() => {
    const userInfo = !!localStorage.getItem('userInfo');
    if (userInfo) {
      setUserInfoSaved(true);
    } else {
      setUserInfoSaved(false);
    }
  }, [userInfoSaved]);

  return isMobile ? <Scenarii /> : <NotMobile />;
};

export default App;
