import React, { useState, useEffect, useRef } from 'react';

import { Box, CircularProgress } from '@mui/material';

const BALL_COLORS = {
  DEFAULT: {
    color: 'black',
    border: '1px black solid'
  },
  VALID: {
    color: 'green',
    border: null
  }
};

export const Calibrate = ({ startTutorial }) => {
  const [top, setTop] = useState(0);
  const count = useRef(0);
  const [ballColor, setBallColor] = useState(BALL_COLORS.DEFAULT);

  const [launchTutorial, setLaunchTutorial] = useState(false);
  const [progress, setProgress] = useState(0);

  const boundFloor = 0;
  const boundLow = 65;
  const boundUp = 75;
  const boundCeil = 90;

  useEffect(() => {
    window.addEventListener('deviceorientation', (e) => {
      if (e.beta < boundLow) {
        setTop(
          Math.max(-100, (-100 * (e.beta - boundLow)) / (boundFloor - boundLow))
        );
        count.current = 0;
        setBallColor(BALL_COLORS.DEFAULT);
        setLaunchTutorial(false);
      } else if (e.beta >= boundLow && e.beta < boundUp) {
        setTop(0);
        setLaunchTutorial(true);
        setTimeout(() => {
          if (count.current === 2) {
            setBallColor(BALL_COLORS.VALID);
            setTimeout(() => {
              startTutorial();
            }, 1000);
          }
          count.current = Math.min(2, count.current + 1);
        }, 1000);
      } else {
        setTop(
          Math.min(100, (100 * (e.beta - boundUp)) / (boundCeil - boundUp))
        );
        count.current = 0;
        setBallColor(BALL_COLORS.DEFAULT);
        setLaunchTutorial(false);
      }
    });
  }, []);

  useEffect(() => {
    if (launchTutorial) {
      setTimeout(() => {
        setProgress((prev) => Math.min(prev + 5, 100));
      }, 50);
    } else {
      setProgress(0);
    }
  }, [launchTutorial, progress]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
      }}
    >
      <Box
        sx={{
          width: '20px',
          height: '60vh',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          borderRadius: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            backgroundColor: 'white'
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            marginTop: `${top}%`,
            backgroundColor: ballColor.color,
            color: 'white'
          }}
        >
          <CircularProgress
            variant="determinate"
            value={progress}
            size={'100%'}
            sx={{ color: 'lightgreen' }}
          />
        </Box>
      </Box>
    </Box>
  );
};
