import React, { useRef, useEffect, useState } from 'react';

import Webcam from 'react-webcam';
import { Box } from '@mui/material';

import { Inclination } from './Inclination';
import { Tutorial } from './Tutorial';
import { Countdown } from './Countdown';
import { Results } from './Results';

import { putS3, triggerMeasurement } from '../../../api/endpoints';

const STEPS = {
  INCLINATION: 'Inclination',
  FRONT: 'Front',
  SIDE: 'Side',
  COUNTDOWN_FRONT: 'Countdown Front',
  COUNTDOWN_SIDE: 'Countdown Side',
  RESULTS: 'Results'
};

export const Camera = ({ previousScreen }) => {
  const searchParams = new URLSearchParams(window.location.search);

  const [step, setStep] = useState(STEPS.INCLINATION); // Change to STEPS.INCLINATION

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  //const [uid, setUid] = useState(searchParams.get('uid') || '');
  const [mid] = useState(searchParams.get('mid') || '');
  const [gender] = useState(searchParams.get('gender') || 'male');

  const [result, setResult] = useState('loading');

  const camRef = useRef(null);

  const nextStep = () => {
    if (step === STEPS.INCLINATION) {
      setStep(STEPS.FRONT);
    } else if (step === STEPS.FRONT) {
      setStep(STEPS.COUNTDOWN_FRONT);
    } else if (step === STEPS.SIDE) {
      setStep(STEPS.COUNTDOWN_SIDE);
    } else if (step === STEPS.COUNTDOWN_FRONT) {
      setStep(STEPS.SIDE);
    } else if (step === STEPS.COUNTDOWN_SIDE) {
      setStep(STEPS.RESULTS);
    }
  };

  const takePhoto = (type) => {
    if (camRef.current) {
      const imageSrc = camRef.current.getScreenshot();
      if (imageSrc) {
        putS3(type, imageSrc).then((v) => {
          if (type === 'side') {
            if (v) {
              triggerMeasurement(mid)
                .then((res) => {
                  if (res) setResult('success');
                  else setResult('error');
                })
                .catch((e) => {
                  console.log('Error : triggerMeasurement -', e);
                  setResult('error');
                });
            } else {
              setResult('error');
            }
          }
        });
      }
    }
    return false;
  };

  const closeTutorialFront = () => {
    setStep(STEPS.COUNTDOWN_FRONT);
  };

  const closeTutorialSide = () => {
    setStep(STEPS.COUNTDOWN_SIDE);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      }}
    >
      {step === STEPS.FRONT && (
        <Tutorial onEnd={closeTutorialFront} gender={gender} index={1} />
      )}
      {step === STEPS.SIDE && (
        <Tutorial onEnd={closeTutorialSide} gender={gender} index={2} />
      )}
      {step === STEPS.INCLINATION && (
        <Inclination previousScreen={previousScreen} startTutorial={nextStep} />
      )}
      {(step === STEPS.COUNTDOWN_FRONT || step === STEPS.COUNTDOWN_SIDE) && (
        <Countdown
          takePhoto={takePhoto}
          end={step === STEPS.COUNTDOWN_SIDE}
          nextStep={nextStep}
        />
      )}
      {step === STEPS.RESULTS && <Results type={result} />}
      <Webcam
        ref={camRef}
        mirrored={true}
        height={screenHeight}
        wdith={screenWidth}
        screenshotFormat="image/png"
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
        videoConstraints={{
          facingMode: 'user',
          aspectRatio: screenHeight / screenWidth
        }}
      />
    </Box>
  );
};
