import React from 'react';
import { Typography } from '@mui/material';

import { outcome } from './utils';
import { useState, useEffect } from 'react';

export const Outcome = ({ type }) => {
  const [smallWidth] = useState(window.outerWidth);
  const searchParams = new URLSearchParams(window.location.search);

  const isMobile = searchParams.get('isMobile') || false;

  useEffect(() => {
    if (isMobile && type === 'success') {
      setTimeout(() => window.close(), 2000);
    }
  }, [type]);

  return (
    <>
      <Typography
        fontSize={30}
        fontWeight={'bold'}
        my={smallWidth ? '20px' : '40px'}
      >
        {outcome[type].title}
      </Typography>
      <img
        src={outcome[type].img}
        style={{
          width: '30%'
        }}
      />
      <Typography
        fontSize={smallWidth ? 15 : 20}
        fontWeight={'regular'}
        mx="30px"
        my="20px"
      >
        {outcome[type].message}
      </Typography>
    </>
  );
};
