import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const Loading = ({ loading, loadingValue, setLoadingValue }) => {
  const [smallWidth] = useState(window.outerWidth < 380);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoadingValue((prev) => Math.min(prev + 1, 90));
      }, 50);
    } else {
      setTimeout(() => {
        setLoadingValue((prev) => Math.min(prev + 10, 100));
      }, 200);
    }
  }, [loadingValue, loading]);

  return (
    <>
      <CircularProgress
        variant="determinate"
        value={loadingValue}
        size={smallWidth ? 150 : 200}
        sx={{
          color: 'rgba(0,0,0,1)',
          position: 'absolute',
          marginTop: '-10vh'
        }}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        size={smallWidth ? 150 : 200}
        sx={{
          color: 'rgba(0,0,0,0.2)',
          position: 'absolute',
          marginTop: '-10vh'
        }}
      />
      <Typography
        fontSize={smallWidth ? 40 : 50}
        sx={{
          color: 'black',
          position: 'absolute', // Position absolue pour le placer au centre
          marginTop: '-10vh'
        }}
      >
        {loadingValue}%
      </Typography>
      <Typography marginTop="25vh" mx="20px" fontSize="20px">
        Nous calculons votre taille idéale !
      </Typography>
    </>
  );
};
