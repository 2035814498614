import React from 'react';
import { Box, Typography } from '@mui/material';

import error from '../../../assets/img/error.png';

export const Error = ({ retry }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        flexDirection: 'column'
      }}
    >
      <Typography fontSize="35px" fontWeight="bold" margin="20px">
        Oups !
      </Typography>
      <img
        src={error}
        style={{
          width: '20%'
        }}
      />
      <Typography width="80%" margin="20px">
        Vous pouvez compléter le parcours{' '}
        <span style={{ fontWeight: 'bold' }}>Questionnaire</span> en cliquant
        sur{' '}
        <span style={{ textDecoration: 'underline' }}>Passer cette étape</span>
      </Typography>
      <Box
        onClick={retry}
        sx={{
          width: '40%',
          height: '50px',
          backgroundColor: 'black',
          margin: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px'
        }}
      >
        <Typography
          color="white"
          fontWeight="bold"
          fontSize="17px"
          letterSpacing={'0.8px'}
        >
          Recommencer
        </Typography>
      </Box>
    </Box>
  );
};
