import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

export const GuideBox = ({ guideline, nextGuideline }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10px'
        }}
      >
        <img
          src={guideline.icon}
          style={{
            height: '80px',
            width: '80px'
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          width: '75%',
          height: '150px'
        }}
      >
        <Typography
          color="black"
          fontWeight="bold"
          fontSize="20px"
          width="100%"
          textAlign="center"
        >
          {guideline.body}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '50px'
        }}
      >
        {[1, 2, 3].map((value, index) => {
          let opacity = '0.25';
          if (guideline.index === value) {
            opacity = '1';
          }
          return (
            <Box
              key={index}
              sx={{
                width: '10px',
                height: '10px',
                backgroundColor: 'black',
                borderRadius: '10px',
                margin: '5px',
                opacity: opacity
              }}
            />
          );
        })}
      </Box>
      <Button
        onClick={nextGuideline}
        variant="contained"
        sx={{
          display: 'flex',
          textTransform: 'none',
          height: '65px',
          width: '65px',
          backgroundColor: 'black',
          border: '1px black solid',
          borderRadius: '50px'
        }}
      >
        <ArrowForward
          sx={{
            width: '30px',
            height: '30px'
          }}
        />
      </Button>
    </>
  );
};
