import React from 'react';

import { Box, Typography, Button } from '@mui/material';

import privacy from '../../../assets/img/privacy.png';

export const Welcome = ({ openCamera }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography font="arial" fontSize={'25px'} fontWeight={'bold'}>
        Your morphology
      </Typography>
      <Typography font="arial" fontSize={'15px'} margin="10px">
        Now we will ask you to put your phone on the floor, step back and take
        two photos: one front and one side.
      </Typography>
      <img
        src={privacy}
        style={{
          width: '80%'
        }}
      />
      <Button
        onClick={openCamera}
        sx={{
          width: '80%',
          padding: '10px',
          backgroundColor: 'black',
          borderRadius: '10px',
          color: 'white'
        }}
      >
        Start the body scan
      </Button>
    </Box>
  );
};
