import { API_ENDPOINT, X_AUTH_TOKEN, BUCKET_NAME } from './constants';

export const pushS3 = (data) => {
  const body = JSON.stringify({
    file_content: data.content,
    file_name: data.path,
    bucket_name: BUCKET_NAME
  });

  fetch(`${API_ENDPOINT}push-s3`, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      authorizationToken: X_AUTH_TOKEN
    },
    body: body
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data['statusCode'] != 200) {
        console.log('error when uploading');
      }
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });
};

export const putS3 = async (type, data) => {
  if (!data) {
    console.log('no data to put in S3');
  }

  const url = localStorage.getItem(`${type}_put_url`);
  if (url) {
    try {
      const fetchResponse = await fetch(data);
      const blob = await fetchResponse.blob();
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': blob.type
        },
        body: blob
      });

      if (response.ok) {
        return true;
      } else {
        console.log('Error', response);
        return false;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      return false;
    }
  } else {
    console.log('no url found');
    return false;
  }
};

export const triggerMeasurement = async (mid) => {
  try {
    if (mid) {
      const success = await fetch(`${API_ENDPOINT}scan-trigger-measurements`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          measure_id: mid
        })
      })
        .then((res) => res.json())
        .then((res) => res)
        .catch((e) => {
          console.log('Error: triggerMeasurement - ', e);
          return false;
        });
      return success;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error with triggerMeasurement:', error);
    return false;
  }
};
