import { Box } from '@mui/material';
import React, { useRef } from 'react';

import { Img, Vid } from './utils';

import img_front_male from '../../../../assets/img/front_img_male_bis.png';
import img_side_male from '../../../../assets/img/side_img_male_bis.png';
import img_front_female from '../../../../assets/img/front_img_female_bis.png';
import img_side_female from '../../../../assets/img/side_img_female_bis.png';

const VIDEO_SRC = {
  male: {
    1: 'https://kleep-public.s3.eu-west-1.amazonaws.com/Demo1_Man.mp4',
    2: 'https://kleep-public.s3.eu-west-1.amazonaws.com/Demo2_Man.mp4'
  },
  female: {
    1: 'https://kleep-public.s3.eu-west-1.amazonaws.com/Demo1_Woman.mp4',
    2: 'https://kleep-public.s3.eu-west-1.amazonaws.com/Demo2_Woman.mp4'
  }
};

const IMG_SRC = {
  male: {
    1: img_front_male,
    2: img_side_male
  },
  female: {
    1: img_front_female,
    2: img_side_female
  }
};

export const Tutorial = ({ onEnd, gender, index }) => {
  const videoRef = useRef(null);
  const useVideo = false;

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        zIndex: 999999,
        position: 'absolute',
        display: 'flex',
        marginTop: '-5vh'
      }}
    >
      {useVideo && (
        <Vid src={VIDEO_SRC[gender][index]} videoRef={videoRef} onEnd={onEnd} />
      )}
      {!useVideo && <Img src={IMG_SRC[gender][index]} onEnd={onEnd} />}
    </Box>
  );
};
