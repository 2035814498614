import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const Vid = ({ videoRef, src, onEnd }) => {
  useEffect(() => {
    videoRef.current.setAttribute('muted', true);
    videoRef.current.defaultMuted = true;
    videoRef.current.playsInline = true;
    videoRef.current.play();
    videoRef.current.addEventListener('ended', onEnd, { once: true });
  }, [onEnd]);

  return (
    <video
      muted
      ref={videoRef}
      playsInline
      style={{ width: '100vw' }}
      controls={false}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export const Img = ({ src, onEnd }) => {
  const [countdown, setCountdown] = useState(6);

  useEffect(() => {
    setTimeout(() => {
      setCountdown((prev) => Math.max(prev - 1, 0));
    }, 1000);
  }, [countdown]);

  useEffect(() => {
    if (countdown === 0) onEnd();
  }, [countdown]);

  return (
    <>
      <Box
        sx={{
          marginTop: '100px',
          marginLeft: '30px',
          backgroundColor: 'black',
          width: '70px',
          height: '70px',
          borderRadius: '70px',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999999999
        }}
      >
        <Typography fontWeight="bold" color="white" fontSize="30px">
          {countdown}
        </Typography>
      </Box>
      <img src={src} style={{ width: '100vw' }} />
    </>
  );
};
