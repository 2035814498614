import React from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

export const Countdown = ({ takePhoto, end, nextStep }) => {
  const [time, setTime] = useState(6000);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime((prev) => Math.max(prev - 100, 0));
    }, 100);

    if (time === 0) {
      let pictureName;
      if (end) {
        pictureName = 'side';
      } else {
        pictureName = 'front';
      }
      setOpacity(1);
      takePhoto(pictureName);
      setTimeout(() => {
        nextStep();
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [time, takePhoto]);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(0);
    }, 100);
  }, [opacity]);

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        zIndex: 999999999999,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: 'white',
          width: '100vw',
          height: '100vh',
          opacity: opacity,
          transition: 'opacity ease-in 0.1s'
        }}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        size={200}
        sx={{
          color: 'rgba(255,255,255,0.1)',
          position: 'absolute'
        }}
      />
      <CircularProgress
        variant="determinate"
        value={Math.floor(100 * (1 - time / 6000))}
        size={200}
        sx={{
          color: 'rgba(255,255,255,0.5)',
          position: 'absolute'
        }}
      />
      <Typography
        fontSize={75}
        sx={{
          color: 'white',
          position: 'absolute', // Position absolue pour le placer au centre
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)' // Centre le texte,
        }}
      >
        {Math.min(6, time === 0 ? time : 1 + Math.floor(time / 1000))}
      </Typography>
    </Box>
  );
};
